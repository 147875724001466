import model from './model';
import { CheckoutService } from '../../services/CheckoutService';
import { WidgetBlocksIDs } from './consts';
import { SPECS } from '../../consts';

export default model.createController(({ flowAPI, $w, controllerConfig }) => {
  const { wixCodeApi } = controllerConfig;
  const checkoutService = new CheckoutService({ flowAPI });

  const getFormattedAmount = (balance: string, currency: string) => {
    const formatCurrency = flowAPI.getCurrencyFormatter();
    return formatCurrency({ value: balance, currency });
  };

  const fetchGiftCardBalance = async (currency: string) => {
    if (!currency) {
      flowAPI.reportError(new Error('Currency not found'));
      return null;
    }
    try {
      const { giftCardInfo } = await checkoutService.getRiseWallet(currency);
      if (!giftCardInfo || parseInt(giftCardInfo.balance!, 10) === 0) {
        return null;
      }

      return getFormattedAmount(giftCardInfo.balance!, giftCardInfo.currency!);
    } catch (error) {
      flowAPI.reportError(error as Error);
      return null;
    }
  };

  const getCreditWidgetLabel = (formattedBalance: string) => {
    return flowAPI.translations.t('creditWidget.memberCredit.label', {
      amount: formattedBalance,
    });
  };

  const updateWidgetContent = (
    label: string,
    shouldSetTimeout: boolean = false,
  ) => {
    $w(WidgetBlocksIDs.Content).label = label;
    shouldSetTimeout
      ? setTimeout(() => $w(WidgetBlocksIDs.Container).expand(), 500)
      : $w(WidgetBlocksIDs.Container).expand();
  };

  const handleEditorOrPreviewMode = () => {
    const siteCurrency = wixCodeApi.site.currency || 'USD';
    const formattedBalance = getFormattedAmount('60.00', siteCurrency);
    const widgetContent = getCreditWidgetLabel(formattedBalance);

    updateWidgetContent(widgetContent);
  };

  const handleSiteMode = async () => {
    const currency = wixCodeApi.site.currency;
    const formattedBalance = await fetchGiftCardBalance(currency!);

    if (formattedBalance) {
      const widgetContent = getCreditWidgetLabel(formattedBalance);
      updateWidgetContent(widgetContent, true);
    }
  };

  return {
    pageReady: async () => {
      const { experiments } = flowAPI;
      const { viewMode } = wixCodeApi.window;

      if (!experiments.enabled(SPECS.RiseAppBalanceWidget)) {
        return;
      }

      if (viewMode === 'Editor' || viewMode === 'Preview') {
        handleEditorOrPreviewMode();
      } else if (viewMode === 'Site') {
        await handleSiteMode();
      }
    },
    exports: {},
  };
});
