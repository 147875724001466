export enum WidgetBlocksIDs {
  Container = '#widget-container',
  Content = '#widget-content',
}

export enum WidgetRoles {
  Container = 'widget-container',
  Content = 'widget-content',
}

export enum WidgetGFPPActions {
  MAIN_ACTION = 'mainAction1',
  LAYOUT = 'layout',
  ADD = 'add',
  CONNECT = 'connect',
  LINK = 'link',
}
